<template>
  <btn
    component-name="btn-mirage"
    :action="{ to: '/' }"
    title="Homepage"
    class="block leading-none max-w-min"
  >
    <svg-logo-mirage class="w-[180px] min-w-[180px]" />
  </btn>
</template>
