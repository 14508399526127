import { useUserStore } from "@/stores/user";

export const useDataLayer = () => {
  const route = useRoute();
  // const userStore = useUserStore();

  function pushDataLayer(obj = {}) {
    try {
      if (typeof window === "undefined") {
        return;
      }

      const params = { ...obj, page_type: route.name, page_url: route.path };

      // ADD USER TO TRACKING //
      /*
      const params = {
        ...obj,
        page_type: route.name,
        page_url: route.path,
        user: { logged: userStore.logged ? true : false },
      };

      if (userStore.logged) {
        params.user.type= userStore.user.type;

        if (userStore.user.type === "professional") {
          params.user.role = "missing attribute in data model";
        }
      }
      */
      // end ADD USER TO TRACKING //

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(params);
      console.log("[useDataLayer] Pushed to dataLayer: ", params);
    } catch (error) {
      console.log("[useDataLayer] Error: ", error);
    }
  }

  return {
    pushDataLayer,
  };
};
