import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";
import { Observer } from "gsap/Observer";
import { SplitText } from "gsap/SplitText";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(
  ScrollTrigger,
  Flip,
  Observer,
  SplitText,
  TextPlugin,
  ScrollToPlugin
);

export default defineNuxtPlugin((nuxtApp) => {
  const modules = {
    gsap,
    ScrollTrigger,
    Flip,
    SplitText,
    Observer,
    TextPlugin,
    ScrollToPlugin,
  };

  Object.assign(window, modules);

  return {
    provide: modules,
  };
});
